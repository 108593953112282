<template>
    <div ref="modalContainer"></div>
    <a-card class="content-wrapper">
        <template #title>
            <CardHeader></CardHeader>
        </template>
        <div class="body-content">
            <a-form layout="inline">
                <a-form-item label="pda编号">
                    <a-input
                            v-model:value="params.code"
                            placeholder="输入pda编号"
                            allow-clear
                    ></a-input>
                </a-form-item>
                <a-form-item label="状态">
                    <a-select
                            v-model:value="params.state"
                            notFoundContent="暂无数据"
                            style="width: 200px"
                            placeholder="请选择"
                            allow-clear
                            :options="pdaStates"
                    ></a-select>
                </a-form-item>
                <a-form-item style="padding-bottom: 10px" label="添加时间">
                    <a-range-picker
                            style="width: 400px"
                            v-model:value="params.createTimeArr"
                            :show-time="{ format: 'HH:mm:ss' }"
                            format="YYYY-MM-DD HH:mm:ss"
                            valueFormat="YYYY-MM-DD HH:mm:ss"
                            :placeholder="['开始时间', '结束时间']"
                    />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="doQuery">
                        <template #icon>
                            <SearchOutlined />
                        </template>
                        查询
                    </a-button>
                </a-form-item>
                <a-form-item>
                    <a-button
                            perm="maintenance:pda:add"
                            class="btn-success"
                            @click="showAddModal"
                    >
                        <template #icon>
                            <PlusCircleOutlined />
                        </template>
                        添加
                    </a-button>
                </a-form-item>
            </a-form>
            <div style="padding: 4px 0">
                <a-table
                        row-key="id"
                        table-layout="fixed"
                        :data-source="dataList.list"
                        :columns="columns"
                        :loading="loading"
                        :pagination="false"
                        :scroll="{ y: 500 }"
                        bordered
                >
                    <template #state="{ text }">
                        <a-tag :color="text == 1 ? 'green' : 'red'">
                            {{ text == 1 ? "启用" : "停用" }}
                        </a-tag>
                    </template>
                    <template #operation="{ record }">
                        <div>
                            <TipButton
                                    perm="maintenance:pda:edit"
                                    btn-class="btn-success"
                                    title="编辑"
                                    @btnClick="showEditModal(record.id)"
                            >
                                <template #default>
                                    <EditOutlined />
                                </template>
                            </TipButton>
                            <TipButton
                                    perm="maintenance:pda:del"
                                    btn-class="btn-danger"
                                    title="删除"
                                    @btnClick="delItem(record.id)"
                            >
                                <template #default>
                                    <DeleteOutlined />
                                </template>
                            </TipButton>
                        </div>
                    </template>
                </a-table>
            </div>
            <a-pagination
                    v-model:current="page"
                    v-model:page-size="pageSize"
                    :total="total"
                    :show-total="total => `${total}条数据`"
                    @change="currentChange"
            >
            </a-pagination>
        </div>
    </a-card>
    <add
            v-if="addShow"
            :modal-ref="$refs.modalContainer"
            @close="hideAddModal"
    ></add>
    <edit
            v-if="editShow"
            :id="currentId"
            :modal-ref="$refs.modalContainer"
            @close="hideEditModal"
    ></edit>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import usePda from "@/hooks/maintenance/usePda";
import usePagination from "@/hooks/usePagination";
import useAddExit from "@/hooks/useAddExit";
import { onMounted } from "vue";
import Add from "@/views/Maintenance/Pda/components/Add";
import Edit from "@/views/Maintenance/Pda/components/Edit";
import useMessage from "@/hooks/useMessage";
import { deletePda } from "@/api/maintenance/pdaApi";
import TipButton from "@/components/TipButton";

export default {
    name: "index",
    components: {
        Edit,
        Add,
        CardHeader,
        TipButton,
    },
    setup() {
        const {
            params,
            dataList,
            total,
            pdaStates,
            loading,
            columns,
            loadData,
        } = usePda();

        const { page, pageSize, currentChange, doQuery, doRefresh } =
            usePagination(loadData);
        const {
            currentId,
            addShow,
            editShow,
            showAddModal,
            showEditModal,
            hideAddModal,
            hideEditModal,
        } = useAddExit(doQuery);

        const { showConfirm, showLoading, closeLoading, showTipMessage, showErr } =
            useMessage();

        onMounted(() => {
            loadData(page.value, pageSize.value);
        });

        const delItem = async id => {
            let action = await showConfirm(
                "删除该pda设备会导致在场收费员无法正常工作，请确认后删除?",
            );
            if (action) {
                await showLoading("正在删除，请稍后...");
                try {
                    await deletePda(id);
                    await closeLoading();
                    await showTipMessage("删除成功", "success");
                    doQuery();
                } catch (e) {
                    await closeLoading();
                    await showErr(e);
                }
            }
        };

        return {
            params,
            page,
            pageSize,
            total,
            pdaStates,
            loading,
            currentChange,
            dataList,
            doQuery,
            doRefresh,
            columns,
            currentId,
            addShow,
            editShow,
            showEditModal,
            showAddModal,
            hideAddModal,
            hideEditModal,
            delItem,
        };
    },
};
</script>

<style scoped></style>
